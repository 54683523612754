export class Settings {

    static endPoints = {
        files: '/files',
        users: '/users',
    };

    static qrStyle = {
        dots: {
            code: 'dots',
            label: 'Puntos',
            radius: 50
        },
        square: {
            code: 'squares',
            label: 'Cuadrados',
            radius: 0
        }
    };

    static backgroundCanvas = {
        default: {
            code: 'default',
            path: 'M 484.857 363.426 c -4.0205 -35.2325 13.6935 -44.081 4.029 -68.612 c -2.176 -5.5335 -7.8455 -15.147 -8.5595 -29.971 V 228.5565 c 0.6885 -23.137 7.6075 -37.1365 6.4175 -54.8675 s 7.7775 -31.1015 7.7775 -31.1015 s -6.6045 -8.7805 -9.6645 -17.3485 c -4.25 -11.9935 3.2215 -33.915 3.2215 -33.915 a 291.312 291.312 90 0 1 -7.752 -46.75 V 17.425 a 72.1055 72.1055 90 0 1 -17.714 0 C 442.3655 14.875 435.489 4.9725 421.6 1.1475 c -18.7 -5.1 -21.7345 8.5 -52.3175 11.8235 c -35.955 3.9525 -44.982 -13.4045 -70.0145 -3.9355 c -5.644 2.1335 -15.4615 7.65 -30.6 8.3895 c -2.7965 0.1275 -4.7175 0 -17.7055 0 c -4.6155 0.1275 -11.288 0.2295 -19.3205 0 c -23.6045 -0.68 -37.893 -7.463 -55.9895 -6.2985 a 57.5875 57.5875 90 0 1 -31.7305 -7.65 s -8.959 6.4685 -17.7055 9.4605 c -12.24 4.199 -34.612 -3.1535 -34.612 -3.1535 a 307.4705 307.4705 90 0 1 -47.7105 7.65 a 168.13 168.13 90 0 1 -26.112 0 V 36.703 C 15.181 56.5505 5.1 63.291 1.1645 76.9335 c -5.236 18.309 8.653 21.301 12.0785 51.2635 c 4.0205 35.2325 -13.6935 44.0895 -4.029 68.6205 c 2.176 5.525 7.8455 15.147 8.5595 29.971 v 36.278 c -0.6885 23.137 -7.6075 37.1365 -6.4175 54.876 c 1.1815 17.6035 -7.7775 31.093 -7.7775 31.093 S 10.2 357.85 13.243 366.35 c 4.25 11.9935 -3.2215 33.915 -3.2215 33.915 a 291.312 291.312 90 0 1 7.752 46.75 V 480.675 a 72.1055 72.1055 90 0 1 17.714 0 c 20.247 2.55 27.1235 12.4525 41.038 16.2775 c 18.7 5.1 21.7345 -8.5 52.3175 -11.8235 c 35.955 -3.9525 44.982 13.4045 70.0145 3.9355 c 5.644 -2.1335 15.4615 -7.65 30.6 -8.3895 c 2.7965 -0.1275 4.7175 0 17.7055 0 c 4.6155 -0.1275 11.288 -0.2295 19.3205 0 c 23.6045 0.68 37.893 7.463 55.9895 6.2985 a 57.5875 57.5875 90 0 1 31.7305 7.65 s 8.959 -6.4685 17.7055 -9.4605 c 12.24 -4.199 34.612 3.1535 34.612 3.1535 a 307.4705 307.4705 90 0 1 47.7105 -7.65 a 168.1385 168.1385 90 0 1 26.112 0 V 454.92 c 2.55 -19.839 12.699 -26.5795 16.609 -40.222 C 502.1715 396.389 488.2825 393.397 484.857 363.426 Z'
        },
        test: {
            code: 'test',
            path: 'M213.1,6.7c-32.4-14.4-73.7,0-88.1,30.6C110.6,4.9,67.5-9.5,36.9,6.7C2.8,22.9-13.4,62.4,13.5,110.9,C33.3,145.1,67.5,170.3,125,217c59.3-46.7,93.5-71.9,111.5-106.1C263.4,64.2,247.2,22.9,213.1,6.7z'
        },
        test2: {
            code: 'test2',
            path: 'M 103 60 L 518 232 L 103 403 L 103 60'
        }
    }
}