// const axios = require('axios').default;
import axios from 'axios'

const Axios = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    timeout: 10000
});

const HttpService = {

    get: async endPoint => {
        console.log('http service get');
        return Axios.get(endPoint)
            .then(res => res.data)
            .catch(e => e);
    },

    postFormDataFile: async file => {
        
        const formData = new FormData();
        formData.append('file', file);

        return Axios.post('/files/upload', formData)
            .then(res => res.data)
            .catch(e => e);
    }


};

export default HttpService;