import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          labels:{
            hype1: 'Did you know that Bordes is a project that was born in 2012 with the idea of diverting the gaze towards the periphery, towards the limit of space?',
            hype2: 'Materiality overflows imperiously to become an edge...that look that deviates to be rethought again...',
            preview: 'preview'
          }
        }
      }
      ,
      es: {
        translation: {
          labels:{
            hype1: '¿Sabías que Bordes es un proyecto que nace en 2012 con la idea de desviar la mirada hacia la periferia, hacia el límite del espacio?',
            hype2: 'La materialidad desborda imperiosamente para convertirse en borde...esa mirada que se desvía para ser nuevamente repensada...',
            preview: 'previsualización',
          }
        }
      }

    }
  });

export default i18n;