import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './HomePubli.css';
import LoaderPubli from './LoaderPubli';
import { useTranslation, Trans } from 'react-i18next';

// + IMAGES +
import logo from '../../assets/imgs/logo.svg';
// - IMAGES -

const HomePubli = () => {

    const { t } = useTranslation();

    const [item, setItem] = useState(false);

    setTimeout(() => {
        setItem(true);
    }, 2500);

    return (
        <>
            {/* LOADER */}
            {!item &&
                <LoaderPubli />
            }
            {/* LOADER */}

            {/* INFO */}
            {item &&
                <>
                    <Container className='home-cont pb-5 mb-5'>
                        <Row className="justify-content-center align-items-center mt-5">
                            <Col xs={12} md={6} className='mt-3'>
                                <h3 className='mt-3 text text-publi-1'>{t('labels.hype1')}</h3>
                                <br></br>
                                <h3 className='mt-3 text text-publi-2'>{t('labels.hype2')}</h3>
                            </Col>
                            <Col xs={12} md={2}></Col>
                            <Col xs={12} md={4} className='mt-3'>
                                <img src={logo} className="d-block w-100 slider-img" alt="" />
                            </Col>
                        </Row>
                    </Container>
                </>
            }
            {/* INFO */}
        </>
    );
}

export default HomePubli;