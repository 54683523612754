import { Routes, Route } from 'react-router-dom';
import HomePubli from './modules/homepubli/HomePubli';
import TopMenuPubli from './components/topmenupubli/TopMenuPubli';
import FooterPubli from './components/footerpubli/FooterPubli';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';

function AppPubli() {
  return (
    <div className='bg-color'>
      <TopMenuPubli />
      <Routes>
        <Route path='/' element={<HomePubli />}></Route>
      </Routes>
      <FooterPubli />
    </div>
  );
}

export default AppPubli;
