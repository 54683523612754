import 'bootstrap/dist/css/bootstrap.min.css';
import './FooterPubli.css';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { ImLinkedin, ImFacebook2, ImTwitter, ImYoutube, ImWhatsapp } from 'react-icons/im';
import { FaInstagram, FaTiktok } from 'react-icons/fa';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// + IMAGES +
import placeholder from '../../assets/imgs/placeholder.png';
// + IMAGES +

const FooterPubli = () => {
    return (
        <div className='bg-footer-publi p-2'>
            <Container>


                <Row className='justify-content-center align-items-center'>
                    {/* <Col xs={4} md={1} className='text-center mt-3 mb-3'>
                        <a href="https://www.linkedin.com/feed/" target="_blank">
                            <ImLinkedin className='text-light logo-social' />
                        </a>
                    </Col> */}
                    <Col xs={4} md={1} className='text-center mt-3 mb-3'>
                        <a href="https://www.facebook.com/bordes.io" target="_blank">
                            <ImFacebook2 className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3 mb-3'>
                        <a href="https://www.instagram.com/bordes.io" target="_blank">
                            <FaInstagram className='text-light logo-social' />
                        </a>
                    </Col>
                    {/* <Col xs={4} md={1} className='text-center mt-3 mb-3'>
                        <a href="https://www.twitter.com/" target="_blank">
                            <ImTwitter className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3 mb-3'>
                        <a href="https://www.youtube.com/" target="_blank">
                            <ImYoutube className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3 mb-3'>
                        <a href="https://www.tiktok.com/" target="_blank">
                            <FaTiktok className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3 mb-3'>
                        <ImWhatsapp className='text-light logo-social' />
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
}

export default FooterPubli;