import { Routes, Route } from 'react-router-dom';
import Home from './modules/home/Home';
import Test from './modules/test/index';
import TopMenu from './components/topmenu/TopMenu';
import Footer from './components/footer/Footer';
import NftShop from './modules/nft-shop/Nft-shop';
import Dematerializations from './modules/dematerializations/Dematerializations';
import Interventions from './modules/interventions/Interventions';
import NftIntervention from './modules/nft-intervention/NftIntervention';
import NftDematerializationTemplate from './modules/nft-dematerialization-template/NftDematerializationTemplate';
import NftInterventionTemplate from './modules/nft-intervention-template/NftInterventionTemplate';
import Collection from './modules/collection/Collection';
import User from './modules/user/User';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';

function App() {
  return (
    <div className='bg-color'>
      <TopMenu />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/nft-shop' element={<NftShop />}></Route>
        <Route path='/dematerializations' element={<Dematerializations />}></Route>
        <Route path='/interventions' element={<Interventions />}></Route>
        <Route path='/nft-intervention' element={<NftIntervention />}></Route>
        <Route path='/nft-dematerialization-template' element={<NftDematerializationTemplate />}></Route>
        <Route path='/nft-intervention-template' element={<NftInterventionTemplate />}></Route>
        <Route path='/collection' element={<Collection />}></Route>
        <Route path='/user' element={<User />}></Route>
        <Route path="/test" element={<Test />}/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
