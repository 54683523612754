import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Nft-shop.css';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Accordion, Table, Dropdown, Modal, Form } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Loader from './Loader';
import { RiShareBoxLine } from 'react-icons/ri';
import { FaRegCopy } from 'react-icons/fa';

// + IMAGES +
import person from '../../assets/imgs/person.jpeg';
import avatar from '../../assets/imgs/avatar.png';
import eth from '../../assets/imgs/eth.png';
import qr from '../../assets/imgs/qr-inter.png';
// - IMAGES -

function OfferModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Haga su oferta!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className='align-items-center'>
                        <Col xs={2} md={1}>
                            <img src={eth} width='20px' alt="" />
                        </Col>
                        <Col xs={10} md={11}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Precio</Form.Label>
                                <Form.Control type="number" placeholder="0.00" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <p className='mt-3'>Expiración de la oferta</p>
                    <Row className='align-items-center mb-3'>
                        <Col xs={12} md={4}>
                            <Form.Select className='mt-2'>
                                <option>Día</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select className='mt-2'>
                                <option>Meses</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select className='mt-2'>
                                <option>Hora</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Col>
                    </Row>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} className='btn-home create' variant='outline'>OFERTAR</Button>
            </Modal.Footer>
        </Modal>
    );
}

const NftShop = () => {
    const data = [{ name: '5/10', uv: 0.2 }, { name: '5/11', uv: 0.5 }];
    const [modalShow, setModalShow] = React.useState(false);

    const navigate = useNavigate();

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </a>
    ));

    const [item, setItem] = useState(false);

    setTimeout(() => {
        setItem(true);
    }, 2500);

    const goToUser = () => {
        navigate('/user');
    }

    return (
        <Container className='pb-5'>
            {/* LOADER */}
            {!item &&
                <Loader />
            }
            {/* LOADER */}

            {/* INFO */}
            {item &&
                <>
                    <Row className='mt-5'>
                        <Col xs={12} md={4}>
                            <img src={qr} width='100%' alt="" />
                            <h3 className='mt-2 nft-name'>VERANO ONIRICO</h3>
                            <Row className='align-items-center mt-3'>
                                <Col>
                                    <p className='text-left subtitle-owner'>Owner</p>
                                </Col>
                                <Col className='align-right'>
                                    <p className='text-right'>
                                        <img src={avatar} className='avatar' alt="" />
                                        <span className='name-owner'>Maria Park</span>
                                    </p>
                                </Col>
                            </Row>

                            <Accordion defaultActiveKey='0' className='accordion mt-3' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Descripción</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi veritatis aut eum id nostrum deleniti beatae itaque ab
                                        facilis impedit ad iusto nam earum, esse modi amet maiores, voluptate possimus.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion defaultActiveKey='0' className='accordion mt-3' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Detalle</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Contract Address</p>
                                            </Col>
                                            <Col>
                                                <p className='text-danger contract'>0x495f...7b5e</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Token ID</p>
                                            </Col>
                                            <Col>
                                                <p className='contract'><FaRegCopy className='text-danger' /> 24032325984718245...</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Token Standard</p>
                                            </Col>
                                            <Col>
                                                <p className='contract'>ERC-1155</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Blockchain</p>
                                            </Col>
                                            <Col>
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <img src={eth} width='10px' alt="" />
                                                    <span className='contract'>Ethereum</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Metadata</p>
                                            </Col>
                                            <Col>
                                                <p className='contract'>Centralized</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Creator Fees</p>
                                            </Col>
                                            <Col>
                                                <p className='contract'>10%</p>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col xs={12} md={1}></Col>
                        <Col xs={12} md={7}>
                            <Row className='align-items-center mt-3'>
                                <Col>
                                    <p className='text-left subtitle'>Propietario</p>
                                </Col>
                                <Col className='align-right'>
                                    <p className='text-right'>
                                        <img src={person} className='avatar' alt="" />
                                        <span className='name'>Cecilia</span>
                                    </p>
                                </Col>
                            </Row>
                            <h1 className='title'>INTERVENCION</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus semper augue ut</p>

                            <p className='subtitle mt-5 mb-1'>PRECIO ACTUAL</p>
                            <div className="d-flex align-items-center">
                                <img src={eth} alt="" />
                                <span className='price'>0.023</span>
                            </div>
                            <div className='cont-dolar'>
                                <p className='dolar'>$9.23</p>
                            </div>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Button className='btn-global mt-2'>COMPRAR</Button>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Button className='offer mt-2' variant='outline' onClick={() => setModalShow(true)}>REALIZAR UNA OFERTA</Button>
                                </Col>
                            </Row>

                            <OfferModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />

                            <Accordion defaultActiveKey="0" className='accordion mt-4' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Ofertas</Accordion.Header>
                                    <Accordion.Body>
                                        <Table hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Price</th>
                                                    <th>USD</th>
                                                    <th>Floor</th>
                                                    <th>Expiration</th>
                                                    <th>From</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src={eth} width='10px' className='eth' alt="" />
                                                        0,26 ETH
                                                    </td>
                                                    <td>$503,78</td>
                                                    <td className='text-muted'>73% above</td>
                                                    <td className='text-muted'>about 6 hours</td>
                                                    <td className='text-danger link' onClick={goToUser}>Jhosua</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={eth} width='10px' className='eth' alt="" />
                                                        0,26 ETH
                                                    </td>
                                                    <td>$503,78</td>
                                                    <td className='text-muted'>73% above</td>
                                                    <td className='text-muted'>about 6 hours</td>
                                                    <td className='text-danger link' onClick={goToUser}>Jhosua</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={eth} width='10px' className='eth' alt="" />
                                                        0,26 ETH
                                                    </td>
                                                    <td>$503,78</td>
                                                    <td className='text-muted'>73% above</td>
                                                    <td className='text-muted'>about 6 hours</td>
                                                    <td className='text-danger link' onClick={goToUser}>Jhosua</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion defaultActiveKey="0" className='accordion mt-3' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Precio Histórico</Accordion.Header>
                                    <Accordion.Body>
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                Ultima semana
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="1">Ultimo Mes</Dropdown.Item>
                                                <Dropdown.Item eventKey="2">Ultimo año</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div style={{ width: '100%', height: 300 }} className='mt-3'>
                                            <ResponsiveContainer width='100%'>
                                                <LineChart width={600} height={300} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>

                    <Accordion defaultActiveKey="0" className='accordion mt-4' flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Ofertas</Accordion.Header>
                            <Accordion.Body>
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Event</th>
                                            <th>Price</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>From</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SALE</td>
                                            <td>
                                                <img src={eth} width='10px' className='eth' alt="" />
                                                0,26
                                            </td>
                                            <td className='text-danger link'>Jhosua</td>
                                            <td className='text-danger link'>Carnahell</td>
                                            <td><RiShareBoxLine className='text-danger' /> Hace 2 Dias</td>
                                        </tr>
                                        <tr>
                                            <td>TRANSFER</td>
                                            <td></td>
                                            <td className='text-danger link'>Jhosua</td>
                                            <td className='text-danger link'>Carnahell</td>
                                            <td><RiShareBoxLine className='text-danger' />  Hace 2 Dias</td>
                                        </tr>
                                        <tr>
                                            <td>MINTED</td>
                                            <td></td>
                                            <td className='text-danger link'>Jorge</td>
                                            <td className='text-danger link'>Cry</td>
                                            <td className='text-muted'>Hace 1 Mes</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            }
            {/* INFO */}
        </Container>
    );
}

export default NftShop;