import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TopMenuPubli.css';
import { Navbar, Nav, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// + IMAGES +
import logo from '../../assets/imgs/logo.svg';
// - IMAGES -

const lngs = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Español' },
};

const TopMenuPubli = () => {
    const { i18n } = useTranslation();

    const changeLng = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Navbar collapseOnSelect expand='lg' className='navbar'>
            <NavLink className='nav-link' to='/'>
                <img src={logo} className='logo' alt="" />
                BORDES
            </NavLink>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <Nav.Link>
                        {/* <Form.Select size="sm" className='lang-select'>
                            <option>Español</option>
                            <option value="2">English</option>
                        </Form.Select> */}

                        <Form.Select size="sm" className='lang-select' onChange={changeLng} value={i18n.language.split('-')[0]} >
                        {Object.keys(lngs).map((lng) => (
                            <>
                            <option value={lng}>
                                {lngs[lng].nativeName}
                            </option>
                            </>
                        ))}
                        </Form.Select>

                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default TopMenuPubli;