import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePubli.css';
import { Row, Col, Placeholder, Container } from 'react-bootstrap';

const LoaderPubli = () => {
    return (
        <Container>
            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} className='text-title' size='lg' />
                    </Placeholder>
                    <br />
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} className='text-title' size='lg' />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={12} size="lg" />
                    </Placeholder>
                </Col>
            </Row>
        </Container>
    );
}

export default LoaderPubli;